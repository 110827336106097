import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M17 3a3 3 0 013 3v15l-8-3-8 3V6a3 3 0 013-3h10zm-4.418 6.772L12 7.572l-.582 2.2-2.271-.126 1.912 1.233L10.237 13 12 11.563 13.763 13l-.822-2.121 1.912-1.233-2.271.126z",
      fill: "#A3E7CB"
    }, null, -1),
    _createElementVNode("path", {
      d: "M20 21l-.351.936A1 1 0 0021 21h-1zm-8-3l.351-.936a1 1 0 00-.702 0L12 18zm-8 3H3a1 1 0 001.351.936L4 21zm8-13.427l.967-.256a1 1 0 00-1.934 0l.967.256zm.582 2.199l-.967.256a1 1 0 001.022.742l-.055-.998zm2.271-.126l.542.84a1 1 0 00-.597-1.839l.055.999zm-1.912 1.233l-.542-.84a1 1 0 00-.39 1.201l.932-.361zM13.763 13l-.631.775a1 1 0 001.564-1.136l-.933.361zM12 11.563l.632-.775a1 1 0 00-1.264 0l.632.775zM10.237 13l-.933-.361a1 1 0 001.564 1.136L10.237 13zm.822-2.121l.932.361a1 1 0 00-.39-1.201l-.543.84zM9.146 9.646l.055-.999a1 1 0 00-.597 1.84l.542-.841zm2.271.126l-.055.998a1 1 0 001.022-.742l-.967-.256zM19 6v15h2V6h-2zm1.351 14.064l-8-3-.702 1.872 8 3 .702-1.872zm-8.702-3l-8 3 .702 1.872 8-3-.702-1.872zM7 4h10V2H7v2zM5 21V6H3v15h2zm6.033-13.171l.582 2.199 1.934-.512-.582-2.199-1.934.512zm1.604 2.942l2.272-.127-.111-1.997-2.271.127.11 1.997zm1.674-1.965L12.4 10.039l1.085 1.68 1.911-1.233-1.084-1.68zM12.01 11.24l.822 2.121 1.865-.722-.822-2.121-1.865.722zm2.386.985l-1.763-1.437-1.264 1.55 1.764 1.437 1.263-1.55zm-3.027-1.437l-1.763 1.437 1.263 1.55 1.764-1.437-1.264-1.55zm-.199 2.573l.822-2.12-1.865-.723-.822 2.12 1.865.723zm.431-3.322L9.69 8.806l-1.084 1.68 1.911 1.233 1.085-1.68zm-2.509.605l2.272.127.11-1.997-2.27-.127-.112 1.997zm3.294-.616l.582-2.2-1.934-.51-.582 2.198 1.934.512zM7 2a4 4 0 00-4 4h2a2 2 0 012-2V2zm14 4a4 4 0 00-4-4v2a2 2 0 012 2h2z",
      fill: "#007780"
    }, null, -1)
  ])))
}
export default { render: render }