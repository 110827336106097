import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M2 13C2 7.477 6.477 3 12 3s10 4.477 10 10a9.985 9.985 0 01-4 8H6a9.985 9.985 0 01-4-8zm10 1a1 1 0 100-2 1 1 0 000 2z",
      fill: "#A3E7CB"
    }, null, -1),
    _createElementVNode("path", {
      d: "M6 21l-.6.8a1 1 0 00.6.2v-1zm12 0v1a1 1 0 00.6-.2L18 21zm-6.707-8.707a1 1 0 001.414 1.414l-1.414-1.414zm6.414-3.586a1 1 0 00-1.414-1.414l1.414 1.414zM3 13a9 9 0 019-9V2C5.925 2 1 6.925 1 13h2zm9-9a9 9 0 019 9h2c0-6.075-4.925-11-11-11v2zM6.6 20.201A8.985 8.985 0 013 13H1c0 3.6 1.73 6.795 4.4 8.8l1.2-1.599zM21 13a8.985 8.985 0 01-3.6 7.201l1.2 1.6C21.27 19.794 23 16.598 23 13h-2zm-9 0v2a2 2 0 002-2h-2zm0 0h-2a2 2 0 002 2v-2zm0 0v-2a2 2 0 00-2 2h2zm-6 9h12v-2H6v2zm8-9c0-.552-.225-1.053-.586-1.414L12 13h2zm-.586-1.414A1.997 1.997 0 0012 11v2l1.414-1.414zm-.707 2.121l.707-.707L12 11.586l-.707.707 1.414 1.414zm.707-.707l4.293-4.293-1.414-1.414L12 11.586 13.414 13z",
      fill: "#007780"
    }, null, -1),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M5.68 8.094A8.047 8.047 0 017.094 6.68l.663.663a1 1 0 01-1.414 1.414l-.663-.663zM4.062 14a8.079 8.079 0 010-2H5a1 1 0 110 2h-.938zm15.876-2a8.069 8.069 0 010 2H19a1 1 0 110-2h.938zM13 5.062V6a1 1 0 11-2 0v-.938a8.079 8.079 0 012 0z",
      fill: "#007780"
    }, null, -1)
  ])))
}
export default { render: render }